var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-menu"},[(_vm.categories)?_c('CategoriesMenu',{staticClass:"site-menu__categories",attrs:{"categories":_vm.categories,"active-category":_vm.activeCategory}}):_vm._e(),_c('div',{staticClass:"site-menu__container"},[_c('SiteDescription',{attrs:{"site":_vm.site}}),(_vm.discounts)?_c('Discounts',{attrs:{"discounts":_vm.discounts}}):_vm._e(),(_vm.categories)?_c('CategoriesMenu',{attrs:{"categories":_vm.categories,"active-category":_vm.activeCategory,"mobile":""}}):_vm._e(),_c('div',{staticClass:"site-menu__categories-list"},[_vm._l((_vm.categories),function(category){return [_c('div',{key:`category-${category.id}`,staticClass:"site-menu__categories-list__item"},[_c('div',{staticClass:"site-menu__categories-list__item__ancor",attrs:{"id":`category-${category.id}`}}),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                        callback: _vm.visibilityChanged,
                        intersection: {
                            rootMargin: '-49% 0px -49% 0px',
                            threshold: 0
                        }
                    }),expression:"{\n                        callback: visibilityChanged,\n                        intersection: {\n                            rootMargin: '-49% 0px -49% 0px',\n                            threshold: 0\n                        }\n                    }"}],attrs:{"id":category.id}},[_c('div',{staticClass:"site-menu__categories-list__title"},[_vm._v(_vm._s(category.title))]),_c('div',{staticClass:"site-menu__products-list"},[_vm._l((category.products),function(product){return [_c('ProductCard',{key:`product-${product.id}`,attrs:{"product":product,"hide-buttons":""},on:{"click":function($event){return _vm.details(product)},"updateSite":_vm.updateSite}})]})],2)])])]})],2),_c('PopupProductDetail',{attrs:{"product":_vm.activeProduct,"hide-buttons":""},on:{"close":_vm.closeDetails,"updateSite":_vm.updateSite},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}})],1),_c('div',{staticClass:"site-menu__cart"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }